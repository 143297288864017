<template>
  <div class="header">
    <div class="menu">
      <router-link :to="{ name: 'Home' }" class="logo-container" :class="$route.name === 'Home' && 'logo-active'">
        <svg
          class="icon"
          style="width: 24px; height: 24px"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" :d="mdiFile" />
        </svg>
      </router-link>
      <router-link :to="{ name: 'Expense' }" class="logo-container" :class="$route.name === 'Expense' && 'logo-active'">
        <svg
          class="icon"
          style="width: 24px; height: 24px"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" :d="mdiCalculator" />
        </svg>
      </router-link>
      <router-link :to="{ name: 'CustomerList' }" class="logo-container" :class="$route.name === 'CustomerList' && 'logo-active'">
        <svg
          class="icon"
          style="width: 24px; height: 24px"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" :d="mdiFileAccount" />
        </svg>
      </router-link>
      <router-link :to="{ name: 'StoreList' }" class="logo-container" :class="$route.name === 'StoreList' && 'logo-active'">
        <svg
          class="icon"
          style="width: 24px; height: 24px"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" :d="mdiStoreMarker" />
        </svg>
      </router-link>
      <router-link :to="{ name: 'VendorList' }" class="logo-container" :class="$route.name === 'VendorList' && 'logo-active'">
        <svg
          class="icon"
          style="width: 24px; height: 24px"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" :d="mdiStoreCog" />
        </svg>
      </router-link>
      <router-link :to="{ name: 'UserList' }" class="logo-container" :class="$route.name === 'UserList' && 'logo-active'">
        <svg
          class="icon"
          style="width: 24px; height: 24px"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" :d="mdiAccountMultiple" />
        </svg>
      </router-link>
    </div>
    <div>
      <a @click="$store.dispatch('logout')" class="user-info">
        <svg
          class="icon"
          style="width: 24px; height: 24px"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" :d="mdiLogoutVariant" />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import { mdiFile, mdiAccountMultiple, mdiStoreCog, mdiStoreMarker, mdiLogoutVariant, mdiCalculator, mdiFileAccount } from "@mdi/js";
export default {
  name: "HeaderComp",
  props: {},
  data() {
    return {
      mdiFile: mdiFile,
      mdiAccountMultiple: mdiAccountMultiple,
      mdiStoreCog: mdiStoreCog,
      mdiLogoutVariant: mdiLogoutVariant,
      mdiStoreMarker: mdiStoreMarker,
      mdiCalculator: mdiCalculator,
      mdiFileAccount: mdiFileAccount
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon {
  cursor: pointer;
  color: #fff;
}
.header {
  height: 100vh;
  width: 103px;
  background-color: #1e2139;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 3;
}
.menu {
  display: flex;
  flex-direction: column;
}
.logo-container {
  width: 100%;
  height: 103px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.logo-active {
  background-color: #7b5cfa;
}
.logo-container:hover {
  background-color: #7b5cfa;
}
.logo {
  width: 40px;
  height: 40px;
  position: relative;
  background-color: white;
  border-radius: 50%;
}
.logo-rect {
  background: #7b5cfa;
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(80% 0, 20% 0, 50% 50%);
}
.user-info {
  width: 100%;
  height: 90px;
  border-top: 1px solid rgb(73, 79, 110);
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-photo {
  border-radius: 50%;
}

@media screen and (max-width: 1024px) {
  .header {
    height: 70px;
    width: 100vw;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 0;
  }
  .menu {
    flex-direction: row;
  }
  .logo-container {
    width: 70px;
    height: 100%;
    border-bottom-right-radius: 0;
    /* border-top-right-radius: 20px; */
  }
  .logo {
    width: 30px;
    height: 30px;
    position: relative;
    background-color: white;
    border-radius: 50%;
  }
  .user-info {
    width: 70px;
    height: 100%;
    border-left: 1px solid rgb(73, 79, 110);
    border-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
