import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/invoice",
    name: "Invoice",
    component: () => import("../views/Invoice/List.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/invoice/:id",
    name: "InvoiceDetail",
    props: true,
    meta: {
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "invoiceDetail" */ "../views/Invoice/Detail.vue"
      ),
  },
  {
    path: "/expense",
    name: "Expense",
    component: () => import("../views/Expense/List.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/expense/:id",
    name: "ExpenseDetail",
    props: true,
    meta: {
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ExpenseDetail" */ "../views/Expense/Detail.vue"
      ),
  },
  {
    path: "/user",
    name: "UserList",
    props: true,
    meta: {
      auth: true,
    },
    component: () => import("../views/User.vue"),
  },
  {
    path: "/vendor",
    name: "VendorList",
    meta: {
      auth: true,
    },
    component: () => import("../views/Vendor/List.vue"),
  },
  {
    path: "/store",
    name: "StoreList",
    meta: {
      auth: true,
    },
    component: () => import("../views/Store/List.vue"),
  },
  {
    path: "/customer",
    name: "CustomerList",
    meta: {
      auth: true,
    },
    component: () => import("../views/Customer/List.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");
  if (to.matched.some((record) => record.meta.auth)) {
    if (!loggedIn) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
